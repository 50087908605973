import React, { useState } from "react";
import { fetchRestaurants } from "./api/placesService";
import Ruleta from "./components/Ruleta";
import Popup from "./components/Popup"; // Importamos el nuevo componente
import { foodData, restaurantData } from "./components/Datos";
import { motion, AnimatePresence } from "framer-motion";
import { Card, CardContent, CardHeader, CardTitle } from "./components/ui/card";

type FoodType = keyof typeof restaurantData;

function App() {
  const [mustSpinFood, setMustSpinFood] = useState(false);
  const [mustSpinRestaurant, setMustSpinRestaurant] = useState(false);
  const [prizeFoodIndex, setPrizeFoodIndex] = useState(0);
  const [prizeRestaurantIndex, setPrizeRestaurantIndex] = useState(0);
  const [selectedFood, setSelectedFood] = useState<FoodType | null>(null);
  const [restaurants, setRestaurants] = useState<
    { name: string; address: string; link: string }[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [selectedRestaurantDetails, setSelectedRestaurantDetails] = useState<{
    name: string;
    address: string;
    link: string;
  } | null>(null);
  const [showPopup, setShowPopup] = useState(true); // Estado para controlar el popup
  const [postalCode, setPostalCode] = useState<string | null>(null); // Guardar el código postal
  const [selectedCountry, setSelectedCountry] = useState("Argentina"); // Argentina como valor predeterminado

  const handleFoodSpinClick = () => {
    const newPrizeIndex = Math.floor(Math.random() * foodData.length);
    setPrizeFoodIndex(newPrizeIndex);
    setMustSpinFood(true);
  };

  const handleStopSpinningFood = async () => {
    setMustSpinFood(false);
    const food = foodData[prizeFoodIndex].option;

    if (!postalCode) return; // Asegurarse de tener un CP antes de continuar
    setLoading(true);

    const fetchedRestaurants = await fetchRestaurants(food, postalCode, selectedCountry);
    setRestaurants(fetchedRestaurants);
    setLoading(false);
  };

  const handleRestaurantSpinClick = () => {
    if (!restaurants.length) return;
    const newPrizeIndex = Math.floor(Math.random() * restaurants.length);
    setPrizeRestaurantIndex(newPrizeIndex);
    setMustSpinRestaurant(true);
  };

  const handleStopSpinningRestaurant = () => {
    setMustSpinRestaurant(false);
    const selectedRestaurantOption = restaurants[prizeRestaurantIndex];
    setSelectedRestaurantDetails(selectedRestaurantOption);
  };

  const handlePopupSubmit = (cp: string, country: string) => {
    setPostalCode(cp);
    setSelectedCountry(country);
    setShowPopup(false);
  };

  const handlePopupClose = () => {
    setShowPopup(false);
  };

  return (
    <div className="min-h-screen bg-gray-100 p-4 md:p-8">
      {showPopup && (
        <Popup onSubmit={handlePopupSubmit} onClose={handlePopupClose} />
      )}
      <div className="max-w-5xl mx-auto">
          <h1 className="text-3xl md:text-5xl font-bold mb-8 text-center text-gray-800">
          Ruleta: ¿Qué vas a comer hoy?
        </h1>

        <motion.div
          className={`gap-8 ${
            selectedFood && restaurants.length > 0 ? "md:grid-cols-2" : "place-items-center grid-cols-1"
          } grid`}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, ease: "easeInOut" }}
        >
          {/* Primera Ruleta */}
          <Card className="bg-white shadow-lg w-full">
            <CardHeader className="text-center">
              <CardTitle className="text-2xl text-gray-800">Comida</CardTitle>
            </CardHeader>
            <CardContent>
              <Ruleta
                data={foodData}
                mustSpin={mustSpinFood}
                prizeIndex={prizeFoodIndex}
                onSpinClick={handleFoodSpinClick}
                onStopSpinning={() => {
                  setMustSpinFood(false);
                  setSelectedFood(foodData[prizeFoodIndex].option as FoodType);
                  handleStopSpinningFood();
                }}
                buttonLabel="Girar comida"
              />
              {selectedFood && (
                <h2 className="text-xl md:text-2xl font-semibold mt-4 text-center text-gray-700">
                  Resultado: {selectedFood}
                </h2>
              )}
            </CardContent>
          </Card>

          {/* Segunda Ruleta */}
          <AnimatePresence>
            {selectedFood && postalCode && restaurants?.length > 0 && (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 20 }}
                transition={{ duration: 0.5 }}
              >
                <Card className="bg-white shadow-lg w-full">
                  <CardHeader className="text-center">
                    <CardTitle className="text-2xl text-gray-800">
                      Restaurante
                    </CardTitle>
                  </CardHeader>
                  <CardContent>
                    {loading ? (
                      <p className="text-center text-gray-600">
                        Cargando restaurantes...
                      </p>
                    ) : (
                      <Ruleta
                        data={restaurants.map((restaurant) => ({
                          option:
                            restaurant.name.length > 20
                              ? restaurant.name.slice(0, 15) + "..."
                              : restaurant.name,
                        }))}
                        mustSpin={mustSpinRestaurant}
                        prizeIndex={prizeRestaurantIndex}
                        onSpinClick={handleRestaurantSpinClick}
                        onStopSpinning={handleStopSpinningRestaurant}
                        buttonLabel="Girar restaurantes"
                      />
                    )}
                  </CardContent>
                </Card>
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>

        {selectedRestaurantDetails && (
          <motion.div
            className="mt-8 text-center"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ delay: 0.5 }}
          >
            <h2 className="text-2xl md:text-3xl font-bold mb-2 text-green-600">
              ¡Te recomendamos {selectedFood} en{" "}
              {selectedRestaurantDetails.name}!
            </h2>
            <p className="text-xl text-gray-700">
              Dirección: {selectedRestaurantDetails.address}
            </p>
            <a
              href={selectedRestaurantDetails.link}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 underline"
            >
              Ver en Google Maps
            </a>
          </motion.div>
        )}

        <div className="place-items-center grid-cols-1 mt-4">
          <span>Esta página funciona sin publicidad gracias a las donaciones de los usuarios. Si te gustaría colaborar:</span>
          <a className="max-w-max block mt-3" href='https://cafecito.app/ruletacomida' rel='noopener' target='_blank'>
            <img srcSet='https://cdn.cafecito.app/imgs/buttons/button_6.png 1x, https://cdn.cafecito.app/imgs/buttons/button_6_2x.png 2x, https://cdn.cafecito.app/imgs/buttons/button_6_3.75x.png 3.75x' 
            src='https://cdn.cafecito.app/imgs/buttons/button_6.png' 
            alt='Invitame un café en cafecito.app' />
          </a>
        </div>
      </div>
    </div>
  );
}

export default App;
